/* src/component/mission/weight/WeightSlider.css */
.weight-slider {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    position: relative;
    overflow: hidden;
}
  
.wavy-bars {
    display: flex;
    justify-content: center;
    align-items: center; /* Align bars vertically center */
    width: 250px;
    height: 80px;
    position: relative;
}

.bar {
    width: 10px;
    margin: 0 2px;
    background-color: #00bcd4;
    transition: transform 0.5s, height 0.5s;
}

.bar:nth-child(1), .bar:nth-child(9) {
    height: 20px;
}

.bar:nth-child(2), .bar:nth-child(8) {
    height: 30px;
}

.bar:nth-child(3), .bar:nth-child(7) {
    height: 40px;
}

.bar:nth-child(4), .bar:nth-child(6) {
    height: 50px;
}

.bar:nth-child(5) {
    height: 60px;
}

.weight-slider.swipe-right .bar {
    transform: translateX(10px);
}

.weight-slider.swipe-left .bar {
    transform: translateX(-10px);
}

.weight-slider.reset .bar {
    transform: translateX(0);
}

/* Hand icon animation */
.hand-icon {
    position: absolute;
    bottom: 3px;
    left: 50%; /* Start at the center */
    transform: translateX(-50%);
    animation: float-horizontal 2s ease-in-out infinite;
    font-size: 24px;
}

/* Horizontal floating animation */
@keyframes float-horizontal {
    0% {
        transform: translateX(-50%); /* Center */
    }
    25% {
        transform: translateX(-250%); /* Move to the left */
    }
    50% {
        transform: translateX(-50%); /* Back to center */
    }
    75% {
        transform: translateX(250%); /* Move to the right */
    }
    100% {
        transform: translateX(-50%); /* Return to center */
    }
}
