.get-started-container {
    transition: margin-top 0.3s ease;
}

@media (min-aspect-ratio: 1/1) {
    .get-started-container {
        margin-top: 45vh;
    }
    .floating-image {
        animation:
            float 3s ease-in-out infinite,
            fadeInOut 3s ease-in-out infinite;
        position: absolute;
        top: 88vh;
        left: 50vw;
        width: 48px;
        height: auto;
    }
}

@media (max-aspect-ratio: 1/1) {
    .get-started-container {
        margin-top: 25vh;
    }
    .floating-image {
        animation:
            float 3s ease-in-out infinite,
            fadeInOut 3s ease-in-out infinite;
        position: absolute;
        top: 50vh;
        left: 50vw;
        width: 28px;
        height: auto;
        /* pointer-events: none; */
    }
}

@keyframes float {
    0% {
        transform: translatey(0px);
    }
    50% {
        transform: translatey(-10px);
    }
    100% {
        transform: translatey(0px);
    }
}

@keyframes fadeInOut {
    0%,
    100% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}

.floating-image {
    animation:
        float 3s ease-in-out infinite,
        fadeInOut 3s ease-in-out infinite;
    position: absolute;
    top: 88vh;
    left: 50vw;
    width: 48px;
    height: auto;
    pointer-events: none;
}

.glowing-button {
    background-color: #6ea9d7;
}

@keyframes glowAnimation {
    0%,
    100% {
        opacity: 0.2;
    }
    50% {
        opacity: 1;
    }
}

.chatbot-image-container2 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10vh;
    padding: 20px;
}
