.backButton {
    position: absolute;
    top: 0px;
    left: 5px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #f3f4f6;
    z-index: 1000;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.backButton::before {
    content: "";
    width: 12px;
    height: 12px;
    border-left: 2.5px solid blue;
    border-bottom: 2.5px solid blue;
    transform: rotate(45deg);
    margin-left: 3px;
}

.backButton:hover {
    background-color: #e5e7eb;
}

.backButton:active {
    background-color: #d1d5db;
}
.chatbot-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 20px;
}

.chatbot-image {
    max-width: 200px;
    max-height: 200px;
    object-fit: contain;
    border-radius: 35px;
}
.hamburger-container {
    position: absolute;
    top: 8px;
    left: 12vw;
    z-index: 9999;
}
