.weight-history-container {
    padding: 20px;
}

.back-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

.graph-container {
    margin-top: 20px;
}

.calendar-container {
    margin-top: 40px;
}

h2 {
    margin-bottom: 10px;
}
