.horizontal-scroll-container {
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    position: relative;
}

.horizontal-scroll-container::-webkit-scrollbar {
    display: none; /* WebKit */
}

.horizontal-scroll-content {
    display: flex;
    padding: 16px 0;
}

.scroll-panel {
    flex: 0 0 auto;
    width: 200px; /* Adjust this value as needed */
    height: 100px; /* Adjust this value as needed */
    margin-right: 16px;
    background-color: #60a5fa; /* bg-blue-400 */
    border-radius: 0.25rem; /* rounded */
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 16px;
    font-size: 14px;
    text-align: center;
}

.scroll-panel:last-child {
    margin-right: 0;
}

/* Shadow indicators */
.horizontal-scroll-container::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 40px;
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        rgba(0, 0, 0, 0.1)
    );
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.horizontal-scroll-container::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 20px;
    background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0),
        rgba(0, 0, 0, 0.1)
    );
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s ease;
}

/* JavaScript will add this class when content is scrollable */
.horizontal-scroll-container.is-scrollable::after,
.horizontal-scroll-container.is-scrollable::before {
    opacity: 1;
}
.scroll-panel-spacer {
    min-width: 50px; /* Adjust this value to control the size of the spacer */
    height: 1px; /* Make it essentially invisible */
}

/* Ensure the last actual panel has a right margin */
.scroll-panel:last-of-type {
    margin-right: 16px; /* Adjust as needed */
}
