.earned-coin-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.coins-container {
    position: relative;
    width: 200px;
    height: 200px;
}

.coin-container {
    position: absolute;
    width: 80px;
    height: 80px;
    top: 50%;
    left: 50%;
    transition: all 1s ease-in-out;
}

.coin-container.move-to-corner {
    transform: translate(calc(50vw - 40px), calc(-50vh + 40px)) !important;
}

.coin {
    width: 100%;
    height: 100%;
    animation: coinRotate 3s infinite linear;
    transition: all 1s ease-in-out;
}

.coin.shrink {
    animation: coinShrink 1s forwards;
}

@keyframes coinRotate {
    0% {
        transform: rotateY(0deg);
    }
    100% {
        transform: rotateY(360deg);
    }
}

@keyframes coinShrink {
    0% {
        transform: scale(1) rotateY(0deg);
    }
    100% {
        transform: scale(0) rotateY(720deg);
    }
}

.sparkle {
    position: absolute;
    width: 5px;
    height: 5px;
    background-color: #ffd700;
    border-radius: 50%;
    opacity: 0;
}

.sparkle1 {
    top: 0;
    left: 50%;
    animation: sparkle 1.5s infinite 0.2s;
}
.sparkle2 {
    top: 50%;
    right: 0;
    animation: sparkle 1.5s infinite 0.4s;
}
.sparkle3 {
    bottom: 0;
    left: 50%;
    animation: sparkle 1.5s infinite 0.6s;
}
.sparkle4 {
    top: 50%;
    left: 0;
    animation: sparkle 1.5s infinite 0.8s;
}

@keyframes sparkle {
    0%,
    100% {
        transform: scale(0);
        opacity: 0;
    }
    50% {
        transform: scale(1);
        opacity: 1;
    }
}

.toast-message {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 18px;
    animation: fadeInOutToast 5s ease-in-out;
    text-align: center;
    max-width: 90vw;
}

@keyframes fadeInOutToast {
    0%,
    100% {
        opacity: 0;
    }
    20%,
    80% {
        opacity: 1;
    }
}
