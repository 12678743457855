.daily-predict-fatloss-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.back-button {
    align-self: flex-start;
    margin-bottom: 30px;
    background: none;
    border: none;
    font-size: 1.2rem;
    color: #333;
    cursor: pointer;
}

.battery-container {
    display: flex;
    align-items: center;
    margin-top: 30px;
}

.battery-body {
    width: 300px;
    height: 66px;
    background: linear-gradient(to bottom, #f0f0f0, #d0d0d0);
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0,0,0,0.1), inset 0 0 5px rgba(0,0,0,0.1),
                0 4px 6px rgba(0,0,0,0.2);
}

.zinc-cylinder {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 40px;
    background: linear-gradient(to right, #bbb, #999);
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: inset -2px 0 3px rgba(0,0,0,0.1);
    z-index: 2;
}

.lightning-bolt {
    font-size: 24px;
    color: #ffd700;
    filter: drop-shadow(0px 1px 1px rgba(0,0,0,0.5));
}

.battery-fill {
    height: 100%;
    background: linear-gradient(to right, #4CAF50, #45a049); /* Default bright green for light mode */
    border-radius: 10px;
    transition: width 0.5s ease-in-out;
    box-shadow: 2px 0 2px rgba(0,0,0,0.1);
    position: relative;
    z-index: 1;
}

/* Dark mode styles */
@media (prefers-color-scheme: dark) {
    .battery-fill {
        background: linear-gradient(to right, #76ff03, #64dd17); /* Bright green for dark mode */
    }
}

.battery-fill::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 20px;
    background: linear-gradient(to right, #45a049, transparent);
    border-radius: 0 10px 10px 0;
}

.battery-tip {
    width: 10px;
    height: 33px;
    background: linear-gradient(to bottom, #f0f0f0, #d0d0d0);
    margin-left: 3px;
    border-radius: 0 5px 5px 0;
    box-shadow: 2px 0 5px rgba(0,0,0,0.1),
                0 4px 6px rgba(0,0,0,0.2);
}

.battery-label {
    display: flex;
    justify-content: space-between;
    width: 300px;
    margin-top: 10px;
}

.battery-label span {
    font-weight: bold;
    color: #333;
}
