.tips-overlay {
    position: fixed;
    top: 20vh;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust opacity as needed */
    display: none; /* Initially hidden */
    transition: display 0.3s ease-in-out;
    z-index: 9999;
}

.tips-overlay.visible {
    display: block;
}

.chatbot-container-t {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(100%);
    transition: transform 0.3s ease-in-out;
    padding: 10px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    max-width: 300px;
    max-height: 30vh; /* Adjust max height for mobile */
    @media (orientation: landscape) {
        max-height: 60vh; /* Adjust max height for landscape */
    }
}

.chatbot-container.visible {
    transform: translateY(0);
}

.chatbot-image-t {
    width: 100%;
    height: auto;
    border-radius: 5px;
}

.chatbot-message-t {
    padding: 10px;
    font-size: 14px;
}

.chatbot-close-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: transparent;
    border: none;
    cursor: pointer;
}
