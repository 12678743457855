.file-input-10 {
  display: none; /* Hide the default file input */
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 4px;
}

.btn-analyze,
.btn-cancel {
  transform: scaleY(0.9); /* Reduce height by 10% */
}