.up-level-medal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Grey overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's on top of other elements */
}

.up-level-medal {
  width: 100px;
  height: 193.537px;
  transition: transform 0.5s ease-in-out;
}

.up-level-medal img {
  width: 100%;
  height: 100%;
}

.up-level-medal.spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}
