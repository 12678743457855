.daily-weigh {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.weigh-header {
    width: 100%;
    max-width: 600px;
    margin-bottom: 20px;
}

.weight-input-container {
    margin-bottom: 20px;
    text-align: center;
}

#weight-input {
    font-size: 24px;
    padding: 10px;
    text-align: center;
    width: 80vw;
}

.weight-input {
    border: 2px solid #6ea9d7;
    border-radius: 0.5rem;
    padding: 8px;
    font-size: 16px;
    outline: none;
}

.weight-input:focus {
    box-shadow: 0 0 5px rgba(110, 169, 215, 0.5);
}

.button-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.weigh-button {
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.weigh-button:hover {
    background-color: #5c8eb8;
}
