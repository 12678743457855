.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

@keyframes ripple {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -8px -8px;
  }
}

.bg-ripple {
  background-image: radial-gradient(
    circle,
    rgba(255,255,255,0.3) 2px,
    transparent 2px
  );
  background-size: 12px 12px;
}

.animate-ripple {
  animation: ripple 2s linear infinite;
}

.profile-reflection::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: radial-gradient(
    circle,
    rgba(255,255,255,0.3) 1px,
    transparent 1px
  );
  background-size: 8px 8px;
  animation: ripple 2s linear infinite;
  pointer-events: none;
  z-index: 1;
}

.text-shadow {
  text-shadow: 
    -1px -1px 1px rgba(255, 255, 255, 0.8),  /* Top-left white shadow for emboss effect */
    1px 1px 1px rgba(0, 0, 0, 0.3),          /* Bottom-right dark shadow for emboss effect */
    2px 2px 4px rgba(59, 130, 246, 0.5);     /* Existing blue shadow */
  color: #3b82f6;  /* Matching blue color */
}

.text-shadow-md {
  text-shadow: 
    -1px -1px 1px rgba(255, 255, 255, 0.8),  /* Top-left white shadow for emboss effect */
    1px 1px 1px rgba(0, 0, 0, 0.3),          /* Bottom-right dark shadow for emboss effect */
    2px 2px 4px rgba(59, 130, 246, 0.5);     /* Medium blue shadow */
}

.text-shadow-input-label {
  text-shadow: 
    -1px -1px 1px rgba(255, 255, 255, 0.8),  /* Top-left white shadow for emboss effect */
    1px 1px 1px rgba(0, 0, 0, 0.5),          /* Darker bottom-right shadow */
    2px 2px 4px rgba(59, 130, 246, 0.7);     /* Darker blue shadow */
}
