.gradient-overlay {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 15%; /* Adjust height as needed */
    background: linear-gradient(to top, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
}

.modal-overlay {
  opacity: 0;
  transition: opacity 0.8s ease-in-out;
}

.modal-overlay.show {
  opacity: 1;
}

.modal-content {
  opacity: 0;
  transform: scale(0.95);
  transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out;
}

.modal-content.show {
  opacity: 1;
  transform: scale(1);
}
