.signup-login-container {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    height: 20vh;
}

.test-login-button {
    background-color: #3498db; /* Blue color */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 20px; /* Rounded corners */
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: block;
    margin: 0 auto;
}

.test-login-button:hover {
    background-color: #2980b9; /* Darker blue on hover */
}
