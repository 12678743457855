.blue-circle {
    position: absolute;
    top: 0px;
    left: 0vw;
    width: 30px;
    height: 6vh;
    border-radius: 70%;
    background-color: #f3f4f6;
    z-index: 1000;
}

.typing-text {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
}

.typing-font {
    font-size: 0.75em;
    margin-right: 5px;
    color: #555;
}

.suggest-text {
    padding: 5px 10px;
    background-color: #6ea9d7;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
