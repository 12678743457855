/* src/component/quiz/FloatingPointer.css */
@keyframes floatHorizontal {
    0%, 100% {
      transform: translateX(0) translateY(-50%);
    }
    50% {
      transform: translateX(-60px) translateY(-50%);
    }
  }
  
  .floating-hand {
    animation: floatHorizontal 2s ease-in-out infinite;
  }