.next-level-container {
    position: relative;
    overflow: hidden;
}

.image-wrapper {
    position: relative;
    padding-top: 65%; /* This will crop the bottom 35% */
    overflow: hidden;
}

.next-level-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
}

.image-wrapper::after {
    content: "";
    position: absolute;
    bottom: -50px;
    left: -10%;
    right: -10%;
    height: 100px;
    background: white;
    border-radius: 50%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.next-level-text {
    position: relative;
    margin-top: -20px;
    z-index: 1;
}

.next-level-text-shadow {
    text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.3);
}
